.App-logo {
  max-width: 250px;
}

.App--background {
  background-repeat: no-repeat;
  background-size: cover;
}

@media(min-width: 720px) {
  .App--deskLh {
    line-height: 70px;
  }
}


